/* React */
import React, { useEffect, useState } from "react";

/* Router */
import { useParams } from "react-router-dom";

/* Material */
import {
  Box,
  Container,
  Typography,
  Paper,
  Avatar,
  Divider,
} from "@mui/material";

/* Icons */
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

/* Services */
import { ShowUser, ShowUserCurrent } from "./util";

/* Functions */
const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

const stringAvatar = (foto, name) => {
  let props = {};

  if (foto) {
    props = {
      src: `${foto}`,
    };
  } else if (name) {
    props = {
      children: `${String(name.split(" ")[0][0]).toUpperCase()}`,
      sx: {
        bgcolor: stringToColor(name),
      },
    };
  }

  return props;
};

export const Thumbnail = (props) => {
  let { id } = useParams();

  let { title } = props;

  const [user, setUser] = useState({});
  const [userCurrent, setUserCurrent] = useState({});

  useEffect(() => {
    let unmounted = false;

    (async function () {
      let resultUser = null;
      let resultUserCurrent = null;
      try {
        await ShowUser().then((response) => {
          resultUser = response.user;
          if (!unmounted) setUser(resultUser);
        });
        await ShowUserCurrent(id).then((response) => {
          resultUserCurrent = response.user;
          if (!unmounted) setUserCurrent(resultUserCurrent);
        });
      } catch (error) {
        setUserCurrent(null);
      }
    })();

    return () => {
      unmounted = true;
    };
  }, [id]);

  return (
    <Box
      sx={{
        height: "240px",
        width: "100%",
        display: "flex",
        alignItems: "end",
        paddingBottom: 1,
        background:
          "linear-gradient(270deg, #00ead3 4.37%, rgba(255, 255, 255, 0) 97.36%), #00b3e7",
      }}
    >
      <Container>
        {title ? (
          <Typography
            variant="h2"
            component="h1"
            fontWeight={400}
            sx={{ color: "#FFFFFF" }}
          >
            {title}
          </Typography>
        ) : (
          <Box maxWidth={360} width="100%" mx={1} mt={1}>
            <Paper>
              <Box
                py={2.5}
                px={2}
                display="flex"
                alignItems="center"
                justifyContent="start"
                height="100px"
              >
                <Avatar
                  alt={userCurrent.name}
                  sx={{ height: "52px", width: "52px" }}
                  {...stringAvatar(userCurrent.photo, userCurrent.name)}
                />
                <Box ml={2} width="100%">
                  <Typography
                    fontSize="14pt"
                    fontWeight="500"
                    color="primary"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    width={"220px"}
                    display="block"
                    overflow="hidden"
                    sx={{
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.primary.main
                          : "#F1F1F1",
                    }}
                  >
                    {user.id === userCurrent.id ? (
                      <IconButton
                        aria-label="upload picture"
                        component="span"
                        size="small"
                        sx={{
                          marginRight: 0.5,
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}

                    {userCurrent.name}
                  </Typography>

                  <Box mb={1} width="100%">
                    <Divider />
                  </Box>

                  <Typography
                    fontSize="10pt"
                    color="secondary"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    width="220px"
                    display="block"
                    overflow="hidden"
                  >
                    {userCurrent.email}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
        )}
      </Container>
    </Box>
  );
};
