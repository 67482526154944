// react
import React from "react";

// router
import {
  useSearchParams,
  // useParams
} from "react-router-dom";

// material
import { Box, Container } from "@mui/material";

// icons
// import BookmarksIcon from "@mui/icons-material/Bookmarks";

// components
import { Sidebar } from "../../../components/app/Sidebar";
import { Thumbnail } from "../../../components/app/Thumbnail";
import { Pipeline } from "../../../components/app/Pipelines";
import { SubTitlePage } from "../../../components/app/Title";

// styles
import { classes, ContentPage } from "./styles";

export const Pipelines = () => {
  // query
  const [searchParams] = useSearchParams();
  const load = searchParams.get("load");

  // params
  // let { id } = useParams();

  return (
    <Sidebar load={load ? true : false}>
      <ContentPage>
        <Box className={classes.page}>
          <Thumbnail title="Pipelines" />

          <Container>
            <Box my={4}>
              <SubTitlePage>Pipeline 1</SubTitlePage>
            </Box>

            <Pipeline title="Ingestion." description="No data yet." />
            <Pipeline
              title="Understanding the data."
              description="No data yet."
            />
            <Pipeline title="Data preparation." description="No data yet." />
            <Pipeline title="Modeling." description="No data yet." />
            <Pipeline title="Model evaluation." description="No data yet." />
            <Pipeline title="Deployment." description="No data yet." />
          </Container>
        </Box>
      </ContentPage>
    </Sidebar>
  );
};
