/* Material */
import { createTheme } from "@mui/material";

import { ptBR } from "@material-ui/core/locale";

export const DarkTheme = createTheme(
  {
    palette: {
      mode: "dark",
      primary: {
        main: "#004f5d",
        dark: "#003741",
        light: "#33727d",
      },
      secondary: {
        main: "#f9f9f9",
        dark: "#aeaeae",
        light: "#fafafa",
      },
      success: {
        main: "#28a745",
        light: "#53b86a",
        dark: "#1c7430",
      },
      white: {
        main: "#ffffff",
        light: "#ffffff",
        dark: "#b2b2b2",
      },
      appBar: {
        main: "#212121",
        light: "#353535",
        dark: "",
      },
      background: {
        default: "#1e1e1e",
        paper: "#212121",
        document: "#212121",
      },
    },
    typography: {
      fontFamily: [
        // "Poppins",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  },
  ptBR
);
