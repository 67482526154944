import React, { useEffect, useState } from "react";

// router
import {
  useSearchParams,
  // useParams
} from "react-router-dom";

import { Container, Box, Tabs, Tab, useTheme } from "@mui/material";

import { Sidebar } from "../../../components/app/Sidebar";

import { Appearance } from "./menu/Appearance";
import { MyAccount } from "./menu/MyAccount";
import { Logout } from "./menu/Logout";

import { classes, StylesSettings } from "./styles";

function TabPanel(props) {
  const theme = useTheme();

  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            mt: 3,
            p: 0,
            [theme.breakpoints.up("md")]: {
              mt: 0,
              pl: 3,
            },
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const manuTab = [
  { id: 0, label: "Appearance", element: <Appearance /> },
  { id: 1, label: "My Account", element: <MyAccount /> },
  { id: 2, label: "Logout", element: <Logout /> },
];

export const Settings = () => {
  const [searchParams] = useSearchParams();

  const load = searchParams.get("load");

  const [menuItem, setMenuItem] = useState(0);

  const handleChangeMenu = (event, newValue) => {
    setMenuItem(newValue);
  };

  useEffect(() => {
    const redirect = parseInt(searchParams.get("type"));

    if (redirect && !isNaN(redirect)) {
      handleChangeMenu(() => {}, redirect);
    }
  }, [searchParams]);

  return (
    <Sidebar load={load ? true : false}>
      <Container>
        <StylesSettings>
          <Box className={classes.root}>
            <Box py={4}>
              <Tabs
                orientation="horizontal"
                value={menuItem}
                onChange={handleChangeMenu}
                aria-label="Settings Menu"
                classes={{
                  root: classes.tab,
                  flexContainer: classes.containerTab,
                  indicator: classes.indicatorTab,
                }}
              >
                {manuTab.map(function (item, index) {
                  return (
                    <Tab
                      key={index}
                      classes={{
                        selected: classes.tabSelected,
                      }}
                      label={item.label}
                      {...a11yProps(item.id)}
                    />
                  );
                })}
              </Tabs>
            </Box>

            <Box width="100%">
              {manuTab.map(function (item, index) {
                return (
                  <TabPanel key={index} value={menuItem} index={item.id}>
                    {item.element}
                  </TabPanel>
                );
              })}
            </Box>
          </Box>
        </StylesSettings>
      </Container>
    </Sidebar>
  );
};
