/* React */
import { useEffect, useState } from "react";

/* Router */
import { Navigate, Outlet, useNavigate } from "react-router-dom";

/* OAuth functions */
import { getToken } from "../../../oauth/fx/token";

/* Functions */
import { VerifyToken } from "../util";

const useAuth = () => {
  const navigate = useNavigate();

  const [isAuth, setIsAuth] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    let token = getToken();

    if (token) {
      const load = async () => {
        await VerifyToken(token).then((response) => {
          let data = response.user;

          if (data) {
            setUser(data);
            setIsAuth(true);
          } else {
            navigate("/logout");
          }
        });
      };

      load();
    } else {
      setIsAuth(false);
    }
  }, [navigate]);

  return { isAuth, user };
};

export const OAuthRoutes = () => {
  const { isAuth, user } = useAuth();

  if (isAuth === null) return null;

  return isAuth ? <Navigate to={`/h/${user.id}`} /> : <Outlet />;
};
