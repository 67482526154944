// react
import React, { useEffect, useState, useCallback } from "react";

// router
import { useSearchParams, useParams } from "react-router-dom";

// material
import { Box, Container } from "@mui/material";

// icons
import BookmarksIcon from "@mui/icons-material/Bookmarks";

// components
import { Sidebar } from "../../../components/app/Sidebar";
import { Thumbnail } from "../../../components/app/Thumbnail";
import { CardDataset } from "../../../components/shared/Card/Dataset/page";

// components page
import { Dataset } from "../../../components/app/Dataset/page";

// styles
import { classes, ContentPage } from "./styles";

// util
import { ListUserDataset } from "./util";

export const Datasets = () => {
  // query
  const [searchParams] = useSearchParams();
  const load = searchParams.get("load");

  // params
  let { id } = useParams();

  // states
  const [userDataset, setUserDataset] = useState([]);

  const loadDataset = useCallback(async () => {
    let unmounted = false;

    (async () => {
      setUserDataset(null);

      await ListUserDataset(id).then(async (response) => {
        const { result } = response;

        if (!unmounted) {
          setTimeout(() => {
            setUserDataset(result);
          }, 800);
        }
      });
    })();

    return () => {
      unmounted = true;
    };
  }, [id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      loadDataset();
    }

    return () => {
      unmounted = true;
    };
  }, [id, loadDataset]);

  return (
    <Sidebar load={load ? true : false}>
      <ContentPage>
        <Box className={classes.page}>
          <Thumbnail title="Datasets" />

          <Dataset loadDataset={loadDataset} />

          <Container>
            <CardDataset
              icon={BookmarksIcon}
              title="My Datasets"
              userDataset={userDataset}
              setUserDataset={setUserDataset}
              refresh={loadDataset}
            />
          </Container>
        </Box>
      </ContentPage>
    </Sidebar>
  );
};
