/* Material */
import { Box, TextField } from "@mui/material";

export function TextArea(props) {
  const {
    label,
    name,
    variant,
    rows,
    value,
    error = null,
    onChange,
    // onBlur,
  } = props;

  return (
    <Box mb={"0.75rem"}>
      <TextField
        multiline
        fullWidth
        label={label}
        rows={rows ? rows : 5}
        value={value}
        name={name}
        variant={variant ? variant : "outlined"}
        onChange={onChange}
        // onBlur={onBlur}
        {...(error && {
          error: true,
          helperText: error,
        })}
      />
    </Box>
  );
}
