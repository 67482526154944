import { api } from "../../../services/api";

import { getToken } from "../../../oauth/fx/token";

export const ShowUser = async () => {
  try {
    const token = getToken();

    let response = await api.get("/token", {
      headers: {
        authorization: `Authorization ${token}`,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const ShowUserCurrent = async (id) => {
  try {
    let response = await api.get("/user", {
      headers: {
        id: id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};
