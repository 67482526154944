// react
import React from "react";

// router
import { useParams } from "react-router-dom";

// material
import {
  Box,
  Paper,
  Typography,
  Link,
  Divider,
  Grid,
  CircularProgress,
} from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

// util
import { UpdateDatasetUser } from "../util";

export const CardDataset = ({
  icon,
  title,
  save,
  saveDescription,
  userDataset,
  refresh,
}) => {
  const CardIcon = icon;

  // params
  let { id } = useParams();

  const disfavor = async (user, id) => {
    await UpdateDatasetUser(user, id, "0").then(async (response) => {});

    refresh();
  };

  return (
    <Box my={5}>
      <Paper elevation={3}>
        <Box p={5}>
          <Typography
            mb={0.25}
            component="h1"
            sx={{
              fontSize: "16pt",
              fontWeight: 500,
            }}
          >
            <CardIcon sx={{ mr: 1 }} />
            {title}
          </Typography>

          <Divider />

          <Box mt={5}>
            <Grid container spacing={2}>
              {userDataset ? (
                userDataset.length > 0 ? (
                  userDataset.map(function (item, index) {
                    return (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        sx={{
                          height: "180px",
                        }}
                      >
                        <Box
                          component={Paper}
                          elevation={3}
                          p={1.75}
                          sx={{
                            borderLeft: (theme) =>
                              `5px solid ${theme.palette.primary.main}`,
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          {item.externalLink ? (
                            <Box textAlign="end">
                              <Link
                                fontSize="11pt"
                                href={item.externalLink}
                                target="_blank"
                                sx={{
                                  color: (theme) =>
                                    theme.palette.mode === "light"
                                      ? theme.palette.primary.main
                                      : theme.palette.secondary.dark,
                                  textDecoration: "none",
                                }}
                              >
                                Open link
                              </Link>
                            </Box>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}

                          <Divider sx={{ mt: 0.5, mb: 2 }} />

                          <Box>
                            <Typography
                              component="h3"
                              fontSize="10pt"
                              fontWeight={600}
                            >
                              Title
                            </Typography>
                            <Typography
                              component="h3"
                              fontSize="11pt"
                              mt={0.5}
                              color="secondary"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                              display="block"
                              overflow="hidden"
                            >
                              {item.title}
                            </Typography>
                          </Box>

                          <Box textAlign="center" my={1}>
                            <IconButton onClick={() => disfavor(id, item.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item xs={12}>
                    <Box py={4} textAlign="center">
                      <Typography component="h6" variant="h6">
                        Oops! We were unable to find results for your search
                      </Typography>
                    </Box>
                  </Grid>
                )
              ) : (
                <Grid item xs={12}>
                  <Box
                    minHeight="180px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box textAlign="center">
                      <CircularProgress />
                      <Typography mt={4}>Loading datasets...</Typography>
                    </Box>
                  </Box>
                </Grid>
              )}

              {save ? (
                <Grid
                  item
                  sx={{
                    width: "280px",
                    height: "180px",
                  }}
                >
                  <Box
                    onClick={save}
                    component={Paper}
                    elevation={3}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      background: (theme) =>
                        theme.palette.mode === "light"
                          ? "#F1F1F1"
                          : theme.palette.background.default,
                      borderLeft: (theme) =>
                        `5px solid ${theme.palette.primary.main}`,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Typography
                      color="primary"
                      component="div"
                      variant="body2"
                      fontWeight={600}
                      mb={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AddIcon sx={{ mr: 1 }} />
                      Add
                    </Typography>

                    {saveDescription ? (
                      <Typography
                        component="div"
                        variant="body2"
                        fontWeight={500}
                        color="secondary"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {saveDescription}
                      </Typography>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </Box>
                </Grid>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
