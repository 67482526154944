// react
import React, { useEffect, useState, useCallback } from "react";

// router
import { useSearchParams, useParams } from "react-router-dom";

// material
import { Box, Container } from "@mui/material";

// icons
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";

// components
import { Sidebar } from "../../../components/app/Sidebar";
import { Thumbnail } from "../../../components/app/Thumbnail";
import { CardProjects } from "../../../components/shared/Card/Projects";
import { CardDataset } from "../../../components/shared/Card/Dataset";
import { CardEvents } from "../../../components/shared/Card/Events";

// components page
import { Dataset } from "../../../components/app/Dataset";
import { About } from "../../../components/app/About";

// styles
import { classes, ContentPage } from "./styles";

// util
import { ListUserDataset, ListEvent, ListProject } from "./util";

import { DrawerEvents } from "./components/Drawer/Events";
import { DrawerProjects } from "./components/Drawer/Projects";

export const Home = () => {
  // query
  const [searchParams] = useSearchParams();
  const load = searchParams.get("load");

  // params
  let { id } = useParams();

  // states
  const [openEvents, setOpenEvents] = useState(false);
  const [openProjects, setOpenProjects] = useState(false);

  const [userDataset, setUserDataset] = useState([]);
  const [userEvents, setUserEvents] = useState([]);
  const [userProjects, setUserProjects] = useState([]);

  const loadDataset = useCallback(async () => {
    let unmounted = false;

    (async () => {
      setUserDataset(null);

      await ListUserDataset(id).then(async (response) => {
        const { result } = response;

        if (!unmounted) {
          setTimeout(() => {
            setUserDataset(result);
          }, 800);
        }
      });
    })();

    return () => {
      unmounted = true;
    };
  }, [id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      loadDataset();
    }

    return () => {
      unmounted = true;
    };
  }, [id, loadDataset]);

  const loadEvents = useCallback(async () => {
    let unmounted = false;

    (async () => {
      setUserEvents(null);

      await ListEvent(id).then(async (response) => {
        const result = response;

        if (!unmounted) {
          setTimeout(() => {
            setUserEvents(result);
          }, 800);
        }
      });
    })();

    return () => {
      unmounted = true;
    };
  }, [id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      loadEvents();
    }

    return () => {
      unmounted = true;
    };
  }, [id, loadEvents]);

  const loadProjects = useCallback(async () => {
    let unmounted = false;

    (async () => {
      setUserProjects(null);

      await ListProject(id).then(async (response) => {
        const result = response;

        if (!unmounted) {
          setTimeout(() => {
            setUserProjects(result);
          }, 800);
        }
      });
    })();

    return () => {
      unmounted = true;
    };
  }, [id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      loadProjects();
    }

    return () => {
      unmounted = true;
    };
  }, [id, loadProjects]);

  return (
    <Sidebar load={load ? true : false}>
      <ContentPage>
        <Box className={classes.page}>
          <Thumbnail />

          <Container>
            <About />
          </Container>

          <Dataset loadDataset={loadDataset} />

          <Container>
            <CardDataset
              icon={BookmarksIcon}
              title="My Datasets"
              userDataset={userDataset}
              setUserDataset={setUserDataset}
              refresh={loadDataset}
            />

            <CardProjects
              icon={EventNoteIcon}
              title="My Projects"
              saveDescription="Share your project"
              userProjects={userProjects}
              setUserProjects={setUserProjects}
              save={() => {
                setOpenProjects(true);
              }}
            />

            <CardEvents
              icon={AutoAwesomeMosaicIcon}
              title="My Events"
              saveDescription="Share your events"
              userEvents={userEvents}
              setUserEvents={setUserEvents}
              save={() => {
                setOpenEvents(true);
              }}
            />
          </Container>
        </Box>
      </ContentPage>

      <DrawerEvents open={openEvents} setOpen={setOpenEvents} />
      <DrawerProjects open={openProjects} setOpen={setOpenProjects} />
    </Sidebar>
  );
};
