// react
import React, { useState, useEffect } from "react";

// router
import { useParams, useNavigate } from "react-router-dom";

// material
import {
  Box,
  Avatar,
  Toolbar,
  Container,
  Menu,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Divider,
  Typography,
  Link,
  Hidden,
} from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ViewListIcon from "@mui/icons-material/ViewList";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";

// styles
import { classes, ContentPage } from "./styles";

// components
import { AppBar } from "./components/AppBar";
import { Loading } from "../Loading";
import { Modal } from "./components/Modal";

// util
import { ShowUser } from "./util";

// functions
import { stringAvatar } from "./fx";

export const Sidebar = ({ load, children }) => {
  // router
  const navigate = useNavigate();

  // params
  let { id } = useParams();

  // states
  const [user, setUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [openModal, setOpenModal] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    let unmounted = false;

    (async function () {
      if (!id) return navigate("/login");

      try {
        await ShowUser().then((response) => {
          const result = response.user;

          if (!unmounted) {
            if (id !== result.id) {
              return navigate(`/h/${result.id}`);
            }

            if (load) {
              setTimeout(() => {
                setUser(result);
              }, 2500);
            } else {
              setUser(result);
            }
          }
        });
      } catch (error) {
        setUser(null);
      }
    })();

    return () => {
      unmounted = true;
    };
  }, [navigate, id, load]);

  return (
    <React.Fragment>
      {user ? (
        <React.Fragment>
          <ContentPage>
            <Box className={classes.content}>
              <Box width="100%" sx={{ overflowX: "hidden" }}>
                <AppBar position="relative" color="inherit">
                  <Container>
                    <Toolbar sx={{ p: "0px !important" }}>
                      <Typography
                        variant="h6"
                        fontSize="11pt"
                        noWrap
                        className={classes.appBarBrand}
                        onClick={() => navigate(`/h/${user.id}`)}
                        sx={{ cursor: "pointer" }}
                      >
                        PREVIOUS RELEASE
                      </Typography>

                      <Hidden smDown>
                        <nav>
                          <Link
                            variant="button"
                            color="text.primary"
                            href={`/events/${user.id}`}
                            className={classes.appBarItem}
                            sx={{ my: 1, mx: 1.5 }}
                          >
                            EVENTS
                          </Link>
                          <Link
                            variant="button"
                            color="text.primary"
                            href={`/datasets/${user.id}`}
                            className={classes.appBarItem}
                            sx={{ my: 1, mx: 1.5 }}
                          >
                            DATASETS
                          </Link>
                          <Link
                            variant="button"
                            color="text.primary"
                            href="#"
                            className={classes.appBarItem}
                            sx={{ my: 1, mx: 1.5 }}
                            onClick={() => setOpenModal(true)}
                          >
                            MODELS
                          </Link>
                          <Link
                            variant="button"
                            color="text.primary"
                            href={`/pipelines/${user.id}`}
                            className={classes.appBarItem}
                            sx={{ my: 1, mx: 1.5 }}
                          >
                            PIPELINES
                          </Link>
                        </nav>
                      </Hidden>

                      {user ? (
                        <React.Fragment>
                          <Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              textAlign="center"
                            >
                              <Tooltip title="Account settings">
                                <IconButton
                                  onClick={handleClick}
                                  size="small"
                                  sx={{ ml: 2 }}
                                  aria-controls={
                                    open ? "account-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  disableRipple={true}
                                >
                                  <Avatar
                                    alt={user.name}
                                    className={classes.avatar}
                                    {...stringAvatar(user.photo, user.name)}
                                  />
                                  <ArrowDropDownIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>

                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={open}
                              onClose={handleClose}
                              onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  overflow: "visible",
                                  filter:
                                    "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                  mt: 1.5,
                                  "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                  },
                                  "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                  },
                                },
                              }}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                            >
                              <Box
                                py="6px"
                                px="16px"
                                onClick={() => navigate(`/account/${user.id}`)}
                                sx={{ cursor: "pointer" }}
                              >
                                <Box
                                  maxWidth="280px"
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Avatar
                                    alt={user.name}
                                    {...stringAvatar(user.photo, user.name)}
                                  />
                                  <Typography
                                    ml={1}
                                    fontSize="12pt"
                                    whiteSpace="nowrap"
                                    textOverflow="ellipsis"
                                    width="210px"
                                    display="block"
                                    overflow="hidden"
                                  >
                                    {user.name}
                                  </Typography>
                                </Box>
                                <Typography
                                  mt={1.5}
                                  mb={1}
                                  fontSize="10pt"
                                  whiteSpace="nowrap"
                                  textOverflow="ellipsis"
                                  width="260px"
                                  display="block"
                                  overflow="hidden"
                                  color="secondary"
                                >
                                  {user.email}
                                </Typography>
                              </Box>

                              <Divider sx={{ marginBottom: 1 }} />

                              <MenuItem
                                onClick={() => navigate(`/events/${user.id}`)}
                              >
                                <ListItemIcon
                                  onClick={() => navigate(`/events/${user.id}`)}
                                >
                                  <ViewListIcon fontSize="small" />
                                </ListItemIcon>
                                Events
                              </MenuItem>

                              <MenuItem
                                onClick={() => navigate(`/datasets/${user.id}`)}
                              >
                                <ListItemIcon>
                                  <AutoAwesomeMotionIcon fontSize="small" />
                                </ListItemIcon>
                                Datasets
                              </MenuItem>

                              <MenuItem onClick={() => setOpenModal(true)}>
                                <ListItemIcon>
                                  <AutoStoriesIcon fontSize="small" />
                                </ListItemIcon>
                                Models
                              </MenuItem>

                              <MenuItem
                                onClick={() =>
                                  navigate(`/pipelines/${user.id}`)
                                }
                              >
                                <ListItemIcon>
                                  <AccountTreeIcon fontSize="small" />
                                </ListItemIcon>
                                Pipelines
                              </MenuItem>

                              <Divider />

                              <MenuItem
                                onClick={() => navigate(`/account/${user.id}`)}
                              >
                                <ListItemIcon>
                                  <SettingsIcon fontSize="small" />
                                </ListItemIcon>
                                Settings
                              </MenuItem>

                              <MenuItem onClick={() => navigate("/logout")}>
                                <ListItemIcon>
                                  <LogoutIcon fontSize="small" />
                                </ListItemIcon>
                                Logout
                              </MenuItem>
                            </Menu>
                          </Box>
                        </React.Fragment>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </Toolbar>
                  </Container>
                </AppBar>

                <Box className={classes.page}>
                  <Box className={classes.pageContainer}>{children}</Box>
                </Box>
              </Box>
            </Box>
          </ContentPage>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {load ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <Loading />
            </Box>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </React.Fragment>
      )}

      <Modal open={openModal} setOpen={setOpenModal} />
    </React.Fragment>
  );
};
