/* Material */
import { styled } from "@mui/material/styles";

const PREFIX = "oauth";

export const classes = {
  root: `${PREFIX}-root`,
  main: `${PREFIX}-main`,
  bannerSignIn: `${PREFIX}-bannerSignIn`,
  cardSignIn: `${PREFIX}-cardSignIn`,
  titleSignIn: `${PREFIX}-titleSignIn`,
  subTitleSignIn: `${PREFIX}-subTitleSignIn`,
  googleButton: `${PREFIX}-googleButton`,
};

export const StylesOAuth = styled("div")(({ theme }) => ({
  [`.${classes.root}`]: {
    height: "100%",
  },
  [`.${classes.main}`]: {
    minHeight: "100vh",
  },
  [`.${classes.bannerSignIn}`]: {
    background:
      "linear-gradient(138deg, rgb(0, 34, 49) 0%, rgb(0, 87, 101) 100%)",
    height: 380,
    position: "absolute",
    width: "100%",
  },
  [`.${classes.cardSignIn}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginTop: 120,
    minHeight: 340,
    maxWidth: 580,
    marginLeft: "auto",
    marginRight: "auto",
  },
  [`.${classes.titleSignIn}`]: {
    marginBottom: theme.spacing(1.5),
    fontWeight: "600",
    color:
      theme.palette.mode === "light" ? theme.palette.primary.main : "#FFFFFF",
  },
  [`.${classes.subTitleSignIn}`]: {
    fontWeight: 400,
    marginBottom: theme.spacing(1.5),
    fontSize: "12pt",
    color:
      theme.palette.mode === "light" ? theme.palette.primary.main : "#898989",

    "& span": {
      fontWeight: "600",
      color:
        theme.palette.mode === "light" ? theme.palette.primary.main : "#CCCCCC",
    },
  },
  [`.${classes.googleButton}`]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0.25rem",
    color: theme.palette.mode === "light" ? "#717171" : "#F9F9F9",
    width: "100%",
    height: "48px",
    maxWidth: 270,
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#000000",
    border: theme.palette.mode === "light" ? "1px solid #CCCCCC" : "#000000",
    textDecoration: "none !important",
    cursor: "pointer",

    "& div": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },

    "& span": {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },

    "&:active": {
      transform: "scale(0.98)",

      transition: "all 0.5s",
      transitionProperty: "all",
      transitionDuration: "0.6s",
      transitionTimingFunction: "ease",
      transitionDelay: "0s",
    },
  },
}));
