// react
import React, { useState } from "react";

// router
import { useParams } from "react-router-dom";

// material
import {
  Box,
  Divider,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  Link,
  Rating,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// icons
import FavoriteIcon from "@mui/icons-material/Favorite";

// components pages
import { ChipTitle } from "../Chip";

// util
import { UpdateDatasetUser } from "../../util";

// components page
const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ff6d75",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

const DatasetRating = ({ active, user, id }) => {
  const [value, setValue] = useState(active);

  const favorite = async () => {
    setValue(1);

    await UpdateDatasetUser(user, id, "1").then(async (response) => {});
  };

  const disfavor = async () => {
    setValue(0);

    await UpdateDatasetUser(user, id, "0").then(async (response) => {});
  };

  return (
    <Box display="flex" justifyContent="end" alignItems="center" mb={1}>
      <StyledRating
        name="size-large"
        size="large"
        max={1}
        icon={<FavoriteIcon fontSize="inherit" />}
        emptyIcon={<FavoriteIcon fontSize="inherit" />}
        value={value}
        onChange={(event, newValue) => {
          newValue === 1 ? favorite() : disfavor();
        }}
      />
      <Typography
        ml={1}
        color="secondary"
        fontSize="11pt"
        sx={{ cursor: "pointer" }}
        onClick={() => {
          value === 0 ? favorite() : disfavor();
        }}
      >
        Save
      </Typography>
    </Box>
  );
};

export const Modal = ({
  open,
  setOpen,
  search,
  data,
  qtd,
  limit,
  setLimit,
  page,
  setPage,
  loadDataset,
  refreshListDatasets,
}) => {
  // params
  let { id } = useParams();

  const handleChangeModalClose = () => {
    setOpen(false);

    setPage(1);
    setLimit(5);

    loadDataset();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);

    // refreshListDatasets();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleChangeModalClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" textAlign="center">
          Search Datasets...
        </DialogTitle>

        <DialogContent
          sx={{
            width: {
              sm: "600px",
              xs: "100%",
            },
          }}
        >
          <Typography
            component="h1"
            variant="body1"
            color="secondary"
            textAlign="center"
          >
            {search}
          </Typography>

          <Divider sx={{ my: 2 }} />

          {data ? (
            data.length > 0 ? (
              <React.Fragment>
                <TablePagination
                  sx={{ display: "flex", justifyContent: "end" }}
                  rowsPerPageOptions={[]}
                  component="div"
                  count={qtd}
                  rowsPerPage={limit}
                  page={page - 1}
                  onPageChange={handleChangePage}
                />

                {data.map(function (item, index) {
                  return (
                    <Box my={3} key={index}>
                      <Paper elevation={2} sx={{ backgroundImage: "none" }}>
                        <Box mb={4}>
                          <Box px={4} py={2}>
                            <Typography
                              component="h5"
                              variant="body1"
                              fontWeight={400}
                              mt={2}
                              mb={4}
                              sx={{
                                cursor: "pointer",
                              }}
                            >
                              <Link
                                href={item.externalLink}
                                target="blank"
                                sx={{
                                  color: (theme) =>
                                    theme.palette.mode === "light"
                                      ? theme.palette.primary.main
                                      : theme.palette.secondary.dark,
                                }}
                              >
                                {item.title}
                              </Link>
                            </Typography>

                            <Box my={2}>
                              <Typography
                                component="p"
                                fontSize="10pt"
                                fontWeight={600}
                              >
                                Tag:{" "}
                              </Typography>

                              <ChipTitle
                                values={item.keyword || []}
                                limit={5}
                              />
                            </Box>

                            <Divider sx={{ my: 3 }} />

                            <Box my={2}>
                              <Typography
                                component="p"
                                fontSize="10pt"
                                fontWeight={600}
                              >
                                Author:{" "}
                              </Typography>

                              <ChipTitle values={item.owner || []} limit={5} />
                            </Box>

                            <Divider sx={{ my: 3 }} />

                            <DatasetRating
                              active={item.idUserDataset !== null ? 1 : 0}
                              user={id}
                              id={item.id}
                            />
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  );
                })}

                <Box mt={4}>
                  <TablePagination
                    sx={{ display: "flex", justifyContent: "end" }}
                    rowsPerPageOptions={[]}
                    component="div"
                    count={qtd}
                    rowsPerPage={limit}
                    page={page - 1}
                    onPageChange={handleChangePage}
                  />
                </Box>
              </React.Fragment>
            ) : (
              <Box textAlign="center" px={5} py={3}>
                <Typography component="h6" variant="h6">
                  Oops! We were unable to find results for your search
                </Typography>

                <Divider sx={{ my: 4 }} />

                <Typography
                  component="h4"
                  variant="h5"
                  sx={{
                    fontStyle: "italic",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.primary.main
                        : theme.palette.secondary.dark,
                  }}
                >
                  {search}
                </Typography>
              </Box>
            )
          ) : (
            <Box
              minHeight="180px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box textAlign="center">
                <CircularProgress />
                <Typography mt={4}>Loading datasets...</Typography>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
