/* React */
import { useContext } from "react";

/* Context */
import { ThemeContext } from ".";

export const useTheme = () => {
  const context = useContext(ThemeContext);

  return context;
};
