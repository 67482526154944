/* React */
import React, { useState } from "react";

/* Material ui */
import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useTheme as MuiUseTheme } from "@mui/material";

import { CardTitle } from "../../../../../components/shared/Card/Main";

/* Contexts */
import { useTheme } from "../../../../../contexts/ThemeProvider/useTheme";

export const Appearance = () => {
  const theme = MuiUseTheme();
  const contextTheme = useTheme();

  const [mode, setMode] = useState(theme.palette.mode);

  const handleModeChange = (event) => {
    setMode(event.target.value);
    contextTheme.handleTheme(event.target.value);
  };

  return (
    <Box my={3}>
      <CardTitle
        title="Appearance Customization"
        description="This setting will only apply in this browser."
      >
        <Box my={2}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography
                component="div"
                variant="span"
                fontSize="10pt"
                fontWeight={500}
                mb={2}
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? `${theme.palette.primary.main} !important`
                      : "#CCCCCC !important",
                }}
              >
                Theme selector
              </Typography>
            </FormLabel>
            <RadioGroup
              mt={5}
              aria-label="mode"
              value={mode}
              onChange={handleModeChange}
            >
              <FormControlLabel value="dark" control={<Radio />} label="Dark" />
              <FormControlLabel
                value="light"
                control={<Radio />}
                label="Light"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </CardTitle>
    </Box>
  );
};
