/* Material */
import { Box, TextField } from "@mui/material";

export function Input(props) {
  const {
    name,
    label,
    variant,
    type,
    value,
    placeholder,
    error = null,
    onChange,
    length,
    // onBlur,
    // styles,
  } = props;

  return (
    <Box mb={"0.75rem"}>
      <TextField
        fullWidth
        label={label}
        placeholder={placeholder ? placeholder : ""}
        variant={variant ? variant : "outlined"}
        type={type ? type : "text"}
        name={name}
        value={value}
        inputProps={{ maxLength: length ? length : 255 }}
        onChange={onChange}
        // onBlur={onBlur}
        {...(error && {
          error: true,
          helperText: error,
        })}
      />
    </Box>
  );
}
