// react
import React, { useState, useEffect } from "react";

// router
import { useParams } from "react-router-dom";

// material
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Divider,
} from "@mui/material";

// icons
import FaceIcon from "@mui/icons-material/Face";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

// util
import { ShowUser, UpdateDescription } from "./util";

export const About = () => {
  // params
  let { id } = useParams();

  // states
  const [user, setUser] = useState(null);
  const [editable, setEditable] = useState(false);
  const [description, setDescription] = useState("");

  const saveDescription = async () => {
    await UpdateDescription(id, description).then((response) => {
      setEditable(false);
    });
  };

  useEffect(() => {
    let unmounted = false;

    (async function () {
      await ShowUser(id).then((response) => {
        const result = response.user;

        if (!unmounted) {
          setDescription(result.description);
          setUser(result);
        }
      });
    })();

    return () => {
      unmounted = true;
    };
  }, [id]);

  return (
    <Box my={5}>
      <Paper elevation={3}>
        <Box p={5}>
          <Typography
            mb={0.25}
            component="h1"
            sx={{
              fontSize: "16pt",
              fontWeight: 500,
            }}
          >
            <FaceIcon sx={{ mr: 1 }} />
            About You
          </Typography>

          <Divider />

          {editable ? (
            <Box py={4}>
              <TextField
                fullWidth
                label="Another description about myself. More and more text."
                multiline
                rows={5}
                defaultValue={description || ""}
                onChange={(event) => setDescription(event.target.value)}
                sx={{
                  borderRadius: "30px !important",
                }}
              />
            </Box>
          ) : (
            <Box py={4} textAlign="center">
              {user ? (
                description ? (
                  <Typography color="secondary">{description}</Typography>
                ) : (
                  <Typography color="secondary">
                    "Another description about myself. More and more text."
                  </Typography>
                )
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Box>
          )}

          <Box textAlign="center">
            {editable ? (
              <React.Fragment>
                <Button
                  onClick={saveDescription}
                  variant="contained"
                  color="success"
                  size="small"
                  startIcon={<SaveIcon />}
                >
                  Save editions
                </Button>
              </React.Fragment>
            ) : (
              <Button
                onClick={() => {
                  setEditable(true);
                }}
                variant="contained"
                color="success"
                size="small"
                startIcon={<EditIcon />}
              >
                Edit my description
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
