/* React */
import { useContext } from "react";

/* Context */
import { AuthContext } from ".";

export const useAuth = () => {
  const context = useContext(AuthContext);

  return context;
};
