import React from "react";

/* Material */
import { Box, TextField, Stack } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MuiDatePicker from "@mui/lab/DatePicker";
import ptBRLocale from "date-fns/locale/pt-BR";

import MuiDateTimePicker from "@mui/lab/DesktopDateTimePicker";
import MuiTimePicker from "@mui/lab/TimePicker";
import MuiMobileDatePicker from "@mui/lab/MobileDatePicker";

export function MobileDatePicker(props) {
  const { name, label, value, onChange } = props;

  const convertDateForm = (name, value) => ({
    target: {
      name,
      value: value ? new Date(value) : null,
    },
  });

  return (
    <Box mb={"0.75rem"}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
        <Stack spacing={3}>
          <MuiMobileDatePicker
            openTo="day"
            views={["day", "month", "year"]}
            label={label}
            value={value}
            name={name}
            inputFormat="dd/MM/yyyy"
            onChange={(date) => onChange(convertDateForm(name, date))}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Stack>
      </LocalizationProvider>
    </Box>
  );
}

export function DatePicker(props) {
  const { name, label, value, onChange } = props;

  const convertDateForm = (name, value) => ({
    target: {
      name,
      value: value ? new Date(value) : null,
    },
  });

  return (
    <Box mb={"0.75rem"}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
        <Stack spacing={3}>
          <MuiDatePicker
            openTo="day"
            views={["day", "month", "year"]}
            label={label}
            value={value}
            name={name}
            onChange={(date) => onChange(convertDateForm(name, date))}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Stack>
      </LocalizationProvider>
    </Box>
  );
}

export function TimePicker(props) {
  const { name, label, value, onChange } = props;

  const convertDateForm = (name, value) => ({
    target: {
      name,
      value: value ? new Date(value) : null,
    },
  });

  return (
    <Box mb={"0.75rem"}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
        <MuiTimePicker
          label={label}
          value={value}
          name={name}
          renderInput={(props) => <TextField {...props} fullWidth />}
          onChange={(date) => onChange(convertDateForm(name, date))}
        />
      </LocalizationProvider>
    </Box>
  );
}

export function DateTimePicker(props) {
  const { name, label, value, onChange } = props;

  const convertDateForm = (name, value) => ({
    target: {
      name,
      value: value ? new Date(value) : null,
    },
  });

  return (
    <Box mb={"0.75rem"}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
        <MuiDateTimePicker
          openTo="day"
          views={["day", "month", "year", "hours", "minutes"]}
          label={label}
          value={value}
          name={name}
          onChange={(date) => onChange(convertDateForm(name, date))}
          renderInput={(props) => <TextField {...props} fullWidth />}
        />
      </LocalizationProvider>
    </Box>
  );
}
