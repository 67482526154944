/* React */
import React, { useState } from "react";

// router
import { useParams } from "react-router-dom";

/* Material */
import { Grid, Box, Button, Divider } from "@mui/material";

/* Icons */
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/* Components */
import FormControl from "../../../../../../components/app/Form/FormControl";
import useForm from "../../../../../../components/app/Form/FormControl/useForm";
import { Controls } from "../../../../../../components/app/Form/Controls";
import { SubTitlePage } from "../../../../../../components/app/Title";
import { Alert } from "../../../../../../components/app/Alert";

/* Functions */
import { CreateProject } from "../../../util";

/* Styles */
import { Drawer, DrawerHeader } from "../../styled/Drawer";

const initialValues = {
  title: "",
  type: "",
  link: "",
  description: "",
};

export const DrawerProjects = (props) => {
  // params
  let { id } = useParams();

  // props
  const { open, setOpen } = props;

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const isEmpty = (value) => {
    const result = value ? "" : "Please fill in this field.";
    return result;
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("title" in fieldValues) {
      temp.title = isEmpty(fieldValues.title);
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    // setValues,
    errors,
    setErrors,
    handleInputChange,
    handleInputBlur,
  } = useForm(initialValues, true, validate);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setMessageError("");
    setOpenError(false);

    setTimeout(async () => {
      try {
        if (validate()) {
          await CreateProject(
            values.title,
            values.type,
            values.link,
            values.description,
            id
          )
            .catch((error) => {
              console.log(error);
              setMessageError("An error occurred while saving. ");
              setOpenError(true);
            })
            .finally(() => {
              goList();
            });
        } else {
          setMessageError("Please correct the fields in red.");
          setOpenError(true);
        }
      } catch (error) {
        // internal error
        setMessageError("An unexpected error occurred in the session.");
        setOpenError(true);
      }
    }, 100);
  };

  const closeDrawer = () => {
    setOpen(!open);
  };

  const goList = () => {
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open} onClose={closeDrawer}>
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            px={2}
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={closeDrawer}
            >
              Voltar
            </Button>
          </Box>
        </DrawerHeader>

        <Divider
          sx={{
            borderColor: (theme) => theme.palette.background.default,
            marginBottom: (theme) => theme.spacing(1.5),
          }}
        />

        <Box px={4} py={2}>
          <Box mb={4}>
            <SubTitlePage>New Project</SubTitlePage>

            <FormControl onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controls.Input
                    label="Title"
                    placeholder="Just a simple text"
                    name="title"
                    value={values.title}
                    error={errors.title}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Select
                    label="Type"
                    name="type"
                    nullValue={true}
                    items={[
                      { title: "Simulation", value: "Simulation" },
                      { title: "Other", value: "Other" },
                    ]}
                    value={values.type}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    label="URL (external link)"
                    placeholder="e.g., Git repository"
                    name="link"
                    value={values.link}
                    error={errors.link}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.TextArea
                    label="Description"
                    name="description"
                    value={values.Description}
                    error={errors.Description}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  {openError ? (
                    <Box mb={2}>
                      <Alert message={messageError} open={openError} />
                    </Box>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}

                  <Controls.Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    width="100%"
                    text="Save"
                    endIcon={<ArrowForwardIcon />}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};
