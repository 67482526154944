/* React */
import { useState, useEffect } from "react";

/* Material */
import { Alert as MuiAlert, Collapse } from "@mui/material";

/* Icons */
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export const Alert = (props) => {
  const { message } = props;
  const { open } = props;

  // states
  const [openError, setOpenError] = useState(false);

  useEffect(() => {
    setOpenError(open);
  }, [props, open]);

  return (
    <Collapse in={openError}>
      <MuiAlert
        variant="standard"
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="error"
            size="small"
            onClick={() => {
              setOpenError(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {message}
      </MuiAlert>
    </Collapse>
  );
};
