import { api } from "../../../services/api";

export const UpdateDatasetUser = async (idUser, idDataset, active) => {
  try {
    let response = await api.put(`/user-dataset/${idUser}`, {
      dataset: idDataset,
      active: active,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};
