import { api } from "../../../services/api";

export const ListUserDataset = async (id) => {
  try {
    let response = await api.get("/user-dataset/collection", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const ListEvent = async (id) => {
  try {
    let response = await api.get("event/colecao", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const ListProject = async (id) => {
  try {
    let response = await api.get("project/colecao", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const CreateEvent = async (
  title,
  online,
  local,
  category,
  description,
  date,
  id
) => {
  try {
    let response = await api.post("event", {
      title,
      online: online === "true" ? true : false,
      category,
      local,
      description,
      date,
      status: "Showing",
      id,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const CreateProject = async (title, type, link, description, id) => {
  try {
    let response = await api.post("project", {
      title,
      type,
      link,
      description,
      id,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};
