// react
import React, { useEffect, useState } from "react";

// material
import { Chip, Button } from "@mui/material";

// icons
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const ChipTitle = (props) => {
  // props
  const { values, limit } = props;

  // states
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    setData(values);

    if (values.length > limit) setCurrentData(values.slice(0, limit));
  }, [values, limit]);

  const showMore = (event) => {
    setCurrentData(values);

    setExpand(true);
  };

  const showLess = (event) => {
    setCurrentData(values.slice(0, limit));

    setExpand(false);
  };

  return (
    <React.Fragment>
      {currentData ? (
        <React.Fragment>
          {currentData.map(function (item, index) {
            return (
              <Chip
                key={index}
                size="small"
                sx={{ m: 0.5 }}
                variant="outlined"
                label={item.title}
              />
            );
          })}

          {expand ? (
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={showLess}
              startIcon={<VisibilityOffIcon />}
              sx={{
                fontWeight: 300,
                textTransform: "capitalize",
                m: 0.5,
              }}
            >
              Show less
            </Button>
          ) : (
            <Button
              size="small"
              variant="contained"
              onClick={showMore}
              startIcon={<RemoveRedEyeIcon />}
              sx={{
                fontWeight: 300,
                textTransform: "capitalize",
                m: 0.5,
              }}
            >
              Show more
            </Button>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {data.length > 0 ? (
            <React.Fragment>
              {data.map(function (item, index) {
                return (
                  <Chip
                    key={index}
                    size="small"
                    sx={{ m: 0.5 }}
                    variant="outlined"
                    label={item.title}
                  />
                );
              })}
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
