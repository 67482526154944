// react
import React from "react";

// material
import { Box, Paper, Typography, Divider } from "@mui/material";

// icons
import AccountTreeIcon from "@mui/icons-material/AccountTree";

export const Pipeline = ({ title, description }) => {
  return (
    <Box my={5}>
      <Paper
        elevation={3}
        sx={{
          borderLeft: (theme) => `5px solid ${theme.palette.primary.main}`,
          width: "100%",
          height: "100%",
        }}
      >
        <Box p={5}>
          <Typography
            mb={0.25}
            component="h1"
            sx={{
              fontSize: "16pt",
              fontWeight: 500,
            }}
          >
            <AccountTreeIcon sx={{ mr: 1 }} />
            {title}
          </Typography>

          <Divider sx={{ mt: 0.5, mb: 2 }} />

          <Box pt={2} textAlign="center">
            <Typography color="secondary">{description}</Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
