// react
import React, { useEffect, useState, useCallback } from "react";

// router
import { useSearchParams, useParams } from "react-router-dom";

// material
import { Box, Container } from "@mui/material";

// icons
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";

// components
import { Sidebar } from "../../../components/app/Sidebar";
import { Thumbnail } from "../../../components/app/Thumbnail";
import { CardProjects } from "../../../components/shared/Card/Projects";
import { CardDataset } from "../../../components/shared/Card/Dataset";
import { CardEvents } from "../../../components/shared/Card/Events";

import { DrawerEvents } from "./components/Drawer/Events";

// components page
import { Dataset } from "../../../components/app/Dataset";
import { About } from "../../../components/app/About";

// styles
import { classes, ContentPage } from "./styles";

// util
import { ListEvent } from "./util";

export const Events = () => {
  // query
  const [searchParams] = useSearchParams();
  const load = searchParams.get("load");

  // params
  let { id } = useParams();

  // states
  const [openEvents, setOpenEvents] = useState(false);
  const [userEvents, setUserEvents] = useState([]);

  const loadEvents = useCallback(async () => {
    let unmounted = false;

    (async () => {
      setUserEvents(null);

      await ListEvent(id).then(async (response) => {
        const result = response;

        console.log("event");
        console.log(result);

        if (!unmounted) {
          setTimeout(() => {
            setUserEvents(result);
          }, 800);
        }
      });
    })();

    return () => {
      unmounted = true;
    };
  }, [id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      loadEvents();
    }

    return () => {
      unmounted = true;
    };
  }, [id, loadEvents]);

  return (
    <Sidebar load={load ? true : false}>
      <ContentPage>
        <Box className={classes.page}>
          <Thumbnail title="Events" />

          <CardEvents
            icon={AutoAwesomeMosaicIcon}
            title="My Events"
            saveDescription="Share your events"
            userEvents={userEvents}
            setUserEvents={setUserEvents}
            save={() => {
              setOpenEvents(true);
            }}
          />
        </Box>
      </ContentPage>

      <DrawerEvents open={openEvents} setOpen={setOpenEvents} />
    </Sidebar>
  );
};
