const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

export const stringAvatar = (foto, name) => {
  let props = {};

  if (foto) {
    props = {
      src: `${foto}`,
    };
  } else if (name) {
    props = {
      children: `${String(name.split(" ")[0][0]).toUpperCase()}`,
      sx: {
        bgcolor: stringToColor(name),
      },
    };
  }

  return props;
};

export const showMessageError = (setMessageError, setOpenError) => {
  const defaultMessageError =
    "An internal error has occurred. Please try again later.";

  setMessageError(defaultMessageError);
  setOpenError(true);
};
