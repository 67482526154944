import { api } from "../../../services/api";

export const ListUserDataset = async (id) => {
  try {
    let response = await api.get("/user-dataset/collection", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};
