/* Router */
import { Routes, Route, Navigate } from "react-router-dom";

/* OAuth pages */
import { Login } from "../../pages/OAuth/Login";
import { Register } from "../../pages/OAuth/Register";
import { Logout } from "../../pages/OAuth/Logout";

/* Private pages */
import { Home } from "../../pages/Dashboard/Home";
import { Events } from "../../pages/Dashboard/Events";
import { Datasets } from "../../pages/Dashboard/Datasets";
import { Pipelines } from "../../pages/Dashboard/Pipelines";
import { Settings } from "../../pages/Dashboard/Settings";

/* Components */
import { OAuthRoutes } from "../../components/routes/OAuthRoutes";
import { PrivateRoutes } from "../../components/routes/PrivateRoutes";

export const AppRoutes = () => {
  return (
    <Routes>
      {/* ================= OAUTH ROUTES ================= */}
      <Route element={<OAuthRoutes />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Route>

      <Route path="/logout" element={<Logout />} />

      {/* ================= PRIVATE ROUTES ================= */}
      <Route element={<PrivateRoutes />}>
        <Route path="/h/:id" element={<Home />} />
        <Route path="/events/:id" element={<Events />} />
        <Route path="/datasets/:id" element={<Datasets />} />
        <Route path="/pipelines/:id" element={<Pipelines />} />
        <Route path="/account/:id" element={<Settings />} />
      </Route>

      {/* ================= ERRORS ================= */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
