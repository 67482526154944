/* React */
import React, { useEffect, useCallback } from "react";

// /* Router */
import { useNavigate } from "react-router-dom";

/* Material ui */
import { Box, Typography, CircularProgress } from "@mui/material";

export const Logout = () => {
  const navigate = useNavigate();

  const load = useCallback(async () => {
    setTimeout(() => {
      navigate("/logout");
    }, 1500);
  }, [navigate]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Box my={5} textAlign="center">
      <CircularProgress color="secondary" />
      <Typography component="div" mt={4}>
        Aguarde...
      </Typography>
    </Box>
  );
};
