/* Material */
import { styled } from "@mui/material/styles";

const PREFIX = "ContentDrawer";

export const classes = {
  content: `${PREFIX}-content`,
  page: `${PREFIX}-page`,
  pageContainer: `${PREFIX}-pageContainer`,
  avatar: `${PREFIX}-avatar`,
  avatarContent: `${PREFIX}-avatarContent`,
  appBarBrand: `${PREFIX}-appBarBrand`,
  appBarItem: `${PREFIX}-appBarItem`,
};

export const ContentPage = styled("div")(({ theme }) => ({
  [`.${classes.content}`]: {
    display: "flex",
  },
  [`.${classes.page}`]: {
    height: "calc(100vh - 65px)",
    width: "100%",
    overflow: "hidden !important",
  },
  [`.${classes.pageContainer}`]: {
    overflowY: "auto !important",
    height: "100%",
    backgroundColor: `${theme.palette.background.default} !important`,
    color: theme.palette.text.primary,
  },
  [`.${classes.avatar}`]: {
    cursor: "pointer",
    marginRight: theme.spacing(1),
  },
  [`.${classes.appBarBrand}`]: {
    flexGrow: 1,
    fontWeight: 500,
    color:
      theme.palette.mode === "light" ? theme.palette.primary.light : "#FFFFFF",
  },
  [`.${classes.appBarItem}`]: {
    fontSize: "10pt",
    fontWeight: 500,
    textDecoration: "none",
    color:
      theme.palette.mode === "light" ? theme.palette.primary.light : "#CCCCCC",
  },
}));
