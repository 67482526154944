// react
import React, { useEffect, useState, useCallback } from "react";

// router
import { useParams } from "react-router-dom";

// material
import {
  Box,
  Container,
  Divider,
  Paper,
  InputBase,
  Button,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";

// icons
import IconButton from "@mui/material/IconButton";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";

// util
import { List } from "./util";

// components pages
import { Modal } from "./components/Modal";

export const Dataset = ({ loadDataset }) => {
  // params
  let { id } = useParams();

  // states
  const [dataset, setDataset] = useState(null);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);

  const [qtd, setQtd] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);

  const [openErrorMessage, setOpenErrorMessage] = useState(false);

  // functions
  const handleClick = () => {
    setOpenErrorMessage(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorMessage(false);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const deleteSearch = () => {
    setSearch("");
  };

  // const loadListDatasets = async () => {
  const loadListDatasets = useCallback(async () => {
    setDataset(null);

    await List(page, limit, search, id).then(async (response) => {
      const { result, qtd } = response;

      Object.keys(result).forEach(async function (item) {
        const { keywords, owners } = result[item];

        const dataKeywords = [];
        const dataOwners = [];

        if (keywords) {
          keywords.split(",").forEach(function (title) {
            dataKeywords.push({ title: title });
          });
        }

        if (owners) {
          owners.split(",").forEach(function (title) {
            dataOwners.push({ title: title });
          });
        }

        result[item].keyword = dataKeywords;
        result[item].owner = dataOwners;
      });

      setTimeout(async () => {
        let qtdPage = qtd.count || 0;

        setDataset(result);
        setQtd(qtdPage);
      }, 1000);
    });
  }, [id, search, page, limit]);

  const submitSearch = async () => {
    if (search !== "") {
      setOpen(true);

      loadListDatasets();
    } else {
      handleClick();
    }
  };

  useEffect(() => {
    loadListDatasets();
  }, [page, limit, loadListDatasets]);

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.primary.main
            : "#323232",
      }}
    >
      <Container>
        <Box p={5}>
          <Typography
            component="h1"
            textAlign="center"
            sx={{
              color: (theme) => theme.palette.primary.contrastText,
              fontSize: "16pt",
              fontWeight: 500,
            }}
          >
            <LibraryBooksIcon sx={{ mr: 1 }} />
            Search Dataset
          </Typography>

          <Box mt={5}>
            <Box maxWidth="60%" width="100%" mx="auto">
              <Paper
                sx={{
                  borderRadius: "30px",
                  border: `1px solid rgb(0, 0, 0, 0.125)`,
                  p: "6px 22px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search..."
                  inputProps={{ "aria-label": "Search..." }}
                  value={search}
                  onChange={handleChangeSearch}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      ev.preventDefault();
                      submitSearch();
                    }
                  }}
                />

                <IconButton
                  type="submit"
                  sx={{ p: "10px", ml: 1 }}
                  aria-label="search"
                  onClick={submitSearch}
                >
                  <SearchIcon />
                </IconButton>

                {search !== "" ? (
                  <React.Fragment>
                    <Divider
                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />

                    <IconButton
                      onClick={deleteSearch}
                      color="secondary"
                      sx={{ p: "10px" }}
                      aria-label="Delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </Paper>

              <Box textAlign="center" mt={4}>
                <Button
                  startIcon={<SearchIcon />}
                  variant="outlined"
                  color="white"
                  size="large"
                  onClick={submitSearch}
                >
                  Search dataset
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>

      <Modal
        search={search}
        data={dataset}
        open={open}
        setOpen={setOpen}
        qtd={qtd}
        limit={limit}
        setLimit={setLimit}
        page={page}
        setPage={setPage}
        loadDataset={loadDataset}
        refreshListDatasets={loadListDatasets}
      />

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openErrorMessage}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Write something
        </Alert>
      </Snackbar>
    </Box>
  );
};
