/* React */
import { useState } from "react";

const useForm = (initialValues, validateOnChange = false, validate) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    let tmpErrors = errors;
    tmpErrors[name] = "";

    setErrors(tmpErrors);
  };

  const handleCompleteChange = (event, value, name) => {
    setValues({
      ...values,
      [name]: value || null,
    });

    let tmpErrors = errors;
    tmpErrors[name] = "";

    setErrors(tmpErrors);
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;

    if (validateOnChange && value) {
      validate({ [name]: value });
    }
  };

  const resetForm = () => {
    setValues(initialValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleCompleteChange,
    handleInputBlur,
    resetForm,
  };
};

export default useForm;
