import React from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { useTheme } from "@mui/material";

export function CardTitle(props) {
  const theme = useTheme();

  const { children, title, description } = props;

  return (
    <Card
      sx={{
        backgroundImage: "none",
      }}
    >
      <Box sx={{ p: theme.spacing(3) }}>
        <Typography
          sx={{ fontSize: 18, fontWeight: 600 }}
          component="h1"
          color="text.primary"
          mb={0.75}
        >
          {title}
        </Typography>
        <Typography sx={{ fontSize: 14 }} component="h2" color="text.secondary">
          {description}
        </Typography>
      </Box>
      <Divider />

      <Box sx={{ p: theme.spacing(3) }}>{children}</Box>
    </Card>
  );
}
