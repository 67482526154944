/* Material */
import { createTheme } from "@mui/material";

import { ptBR } from "@material-ui/core/locale";

export const LightTheme = createTheme(
  {
    palette: {
      mode: "light",
      primary: {
        main: "#004f5d",
        dark: "#003741",
        light: "#33727d",
      },
      secondary: {
        main: "#6c757d",
        dark: "#4b5157",
        light: "#899097",
      },
      success: {
        main: "#28a745",
        light: "#53b86a",
        dark: "#1c7430",
      },
      white: {
        main: "#ffffff",
        light: "#ffffff",
        dark: "#b2b2b2",
      },
      appBar: {
        main: "#ffffff",
        light: "#d9d9d9",
        dark: "",
      },
      background: {
        default: "#e5e5e5",
        paper: "#ffffff",
        document: "#ffffff",
      },
    },
    typography: {
      fontFamily: [
        "Poppins",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  },
  ptBR
);
