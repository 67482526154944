/* Material */
import { styled } from "@mui/material/styles";

const PREFIX = "ContentDrawer";

export const classes = {
  page: `${PREFIX}-page`,
};

export const ContentPage = styled("div")(({ theme }) => ({
  [`.${classes.page}`]: {
    minHeight: "calc(100vh - 65px)",
    backgroundColor: theme.palette.background.document,
    overflow: "auto !important",
  },
}));
