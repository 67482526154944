import axios from "axios";

let baseURL = "";

switch (process.env.NODE_ENV) {
  case "production":
    baseURL = "https://api.neuroshared.com";
    break;

  case "development":
    baseURL = "http://localhost:8686";
    break;

  default:
    baseURL = "https://api.neuroshared.com";
    break;
}

export const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
