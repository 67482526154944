/* Services */
import { api } from "../../services/api";

export function setUserLocalStorage(user) {
  localStorage.setItem("us-token", JSON.stringify(user));
}

export function removeUserLocalStorage() {
  localStorage.removeItem("us-token");
}

export function getUserLocalStorage() {
  const json = localStorage.getItem("us-token");

  if (!json) {
    return null;
  }

  const user = JSON.parse(json);
  return user || null;
}

export async function LoginRequest(googleId, email, name) {
  try {
    let response = null;
    let token = null;

    response = await api.get("/oauth", {
      headers: {
        id: googleId,
      },
    });

    token = response.data.token;

    if (!token) {
      response = await api.post("/oauth", {
        id: googleId,
        email,
        name,
        photo: "/src/img/user.png",
      });

      token = response.data.token;
    }

    return token;
  } catch (error) {
    return null;
  }
}
