/* React */
import React, { useState } from "react";

/* Router */
import { useNavigate } from "react-router-dom";

/* Material ui */
import { Box, Grid, Typography, Paper, Link } from "@mui/material";

/* Context */
import { useAuth } from "../../../contexts/AuthProvider/useAuth";

/* Styles */
import { StylesOAuth, classes } from "../styles";

/* OAuth */
import { GoogleLogin } from "react-google-login";
import credentials from "../../../oauth/key/credentials.json";

/* Components */
import { Alert } from "../../../components/app/Alert";

/* Images */
import googleImage from "../../../assets/img/google.png";

export function Register() {
  const navigate = useNavigate();
  const auth = useAuth();

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const responseGoogle = async (response) => {
    try {
      const { profileObj } = response;

      if (profileObj) {
        const { googleId, email, name } = profileObj;

        let response = await auth.authenticate(googleId, email, name);

        if (response) {
          navigate(`/h/${googleId}?load=true`);
        }
      } else {
        showMessageError();
      }
    } catch (error) {
      showMessageError();
    }
  };

  const showMessageError = () => {
    setMessageError("An internal error has occurred. Please try again later.");
    setOpenError(true);
  };

  return (
    <StylesOAuth className={classes.root}>
      <Box className={classes.main}>
        <Box className={classes.bannerSignIn}>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            component="main"
          >
            <Grid item xs={11} sm={10} md={7} lg={6}>
              <Paper className={classes.cardSignIn}>
                <Box p={5}>
                  <Typography
                    component="h1"
                    variant="h5"
                    className={classes.titleSignIn}
                  >
                    Make your registration
                  </Typography>

                  <Typography
                    component="h2"
                    variant="h6"
                    className={classes.subTitleSignIn}
                  >
                    Create your secure account and{" "}
                    <Typography component="span">try it free!</Typography>
                  </Typography>

                  <Box my={4}>
                    <GoogleLogin
                      render={(renderProps) => (
                        <button
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          className={classes.googleButton}
                        >
                          <Box>
                            <img
                              width="20px"
                              src={googleImage}
                              alt="oauth google"
                            />
                          </Box>

                          <Typography component="span">
                            Sign in with Google
                          </Typography>
                        </button>
                      )}
                      clientId={credentials.web.client_id}
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                    />

                    {openError ? (
                      <Box mt={4}>
                        <Alert message={messageError} open={openError} />
                      </Box>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </Box>

                  <Box my={2}>
                    <Typography
                      sx={{
                        fontWeight: (theme) =>
                          theme.palette.mode === "light" ? 500 : 400,
                      }}
                    >
                      Already have an account?{" "}
                      <Link
                        href="/login"
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "light"
                              ? `${theme.palette.primary.main} !important`
                              : `${theme.palette.primary.light} !important`,
                        }}
                      >
                        Login
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </StylesOAuth>
  );
}
