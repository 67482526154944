// react
import React from "react";

// material
import {
  Box,
  Divider,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

export const Modal = ({ open, setOpen }) => {
  const handleChangeModalClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleChangeModalClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" textAlign="center">
          Models
        </DialogTitle>

        <DialogContent
          sx={{
            width: {
              sm: "600px",
              xs: "100%",
            },
          }}
        >
          <Divider sx={{ my: 2 }} />

          <Box textAlign="center" px={5} py={3}>
            <Typography component="h6" variant="h6">
              We are work on this
            </Typography>

            <Divider sx={{ my: 4 }} />

            <Typography
              component="h4"
              variant="h5"
              sx={{
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.primary.main
                    : theme.palette.secondary.dark,
              }}
            >
              Find and organize your models
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
