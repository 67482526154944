import { api } from "../../../services/api";

export const ShowUser = async (id) => {
  try {
    let response = await api.get("/user", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const UpdateDescription = async (id, description) => {
  try {
    let response = await api.put(`/user-description/${id}`, {
      description,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};
