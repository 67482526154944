// react
import React from "react";

// material
import { Box, Paper, Typography, Link, Divider, Grid } from "@mui/material";

// icons
import AddIcon from "@mui/icons-material/Add";

// functions
const formatDate = (date) => {
  let dateString = new Date(date)
    .toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace(/\//g, "/");

  return dateString;
};

export const CardEvents = ({
  icon,
  title,
  save,
  saveDescription,
  userEvents,
  setUserEvents,
}) => {
  const CardIcon = icon;

  return (
    <Box my={5}>
      <Paper elevation={3}>
        <Box p={5}>
          <Typography
            mb={0.25}
            component="h1"
            sx={{
              fontSize: "16pt",
              fontWeight: 500,
            }}
          >
            <CardIcon sx={{ mr: 1 }} />
            {title}
          </Typography>

          <Divider />

          <Box mt={5}>
            <Grid container spacing={2}>
              {userEvents ? (
                userEvents.length > 0 ? (
                  userEvents.map(function (item, index) {
                    return (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        sx={{
                          // width: "280px",
                          minHeight: "180px",
                        }}
                      >
                        <Box
                          component={Paper}
                          elevation={3}
                          p={2}
                          sx={{
                            borderLeft: (theme) =>
                              `5px solid ${theme.palette.primary.main}`,
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Typography component="h3" fontSize="11pt">
                            {item.category}
                          </Typography>

                          <Divider />

                          <Typography component="h3" fontSize="14pt" mt={0.5}>
                            {item.title}
                          </Typography>

                          <Typography component="h4" fontSize="10pt" mt={2}>
                            {item.description}
                          </Typography>

                          <Typography component="h4" fontSize="10pt" mt={2}>
                            {formatDate(item.date)}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item xs={12}>
                    <Box py={4} textAlign="center">
                      <Typography component="h6" variant="h6">
                        Oops! We were unable to find results for your search
                      </Typography>
                    </Box>
                  </Grid>
                )
              ) : (
                <React.Fragment></React.Fragment>
              )}

              {save ? (
                <Grid
                  item
                  sx={{
                    width: "280px",
                    height: "180px",
                  }}
                >
                  <Box
                    onClick={save}
                    component={Paper}
                    elevation={3}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      background: (theme) =>
                        theme.palette.mode === "light"
                          ? "#F1F1F1"
                          : theme.palette.background.default,
                      borderLeft: (theme) =>
                        `5px solid ${theme.palette.primary.main}`,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Typography
                      component="div"
                      variant="body2"
                      fontWeight={600}
                      mb={1}
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === "light"
                            ? theme.palette.primary.main
                            : "#F1F1F1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AddIcon sx={{ mr: 1 }} />
                      Add
                    </Typography>

                    {saveDescription ? (
                      <Typography
                        component="div"
                        variant="body2"
                        fontWeight={500}
                        color="secondary"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {saveDescription}
                      </Typography>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </Box>
                </Grid>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
