import { api } from "../../../services/api";

export const List = async (page, limit, search, id) => {
  try {
    let response = await api.get(
      `/pagination-dataset/collection?search=${search}`,
      {
        headers: {
          startindex: page,
          limit: limit,
          id: id,
        },
      }
    );

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const UpdateDatasetUser = async (idUser, idDataset, active) => {
  try {
    let response = await api.put(`/user-dataset/${idUser}`, {
      dataset: idDataset,
      active: active,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};
