/* React */
import React, { useState, useEffect } from "react";

/* Material ui */
import { Box, Avatar, Grid, Typography } from "@mui/material";

/* Components */
import { Alert } from "../../../../../components/app/Alert";
import { CardTitle } from "../../../../../components/shared/Card/Main";

/* Services */
import { ShowUser } from "../../util";

/* Functions */
const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

const stringAvatar = (foto, name) => {
  let props = {};

  if (foto) {
    props = {
      src: `${foto}`,
    };
  } else if (name) {
    props = {
      children: `${String(name.split(" ")[0][0]).toUpperCase()}`,
      sx: {
        bgcolor: stringToColor(name),
      },
    };
  }

  return props;
};

export const MyAccount = () => {
  const [user, setUser] = useState({});

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  useEffect(() => {
    let unmounted = false;

    (async function () {
      try {
        await ShowUser().then((response) => {
          let result = response.user;

          if (!unmounted) {
            setUser(result);
          }
        });
      } catch (error) {
        setUser(null);
        showMessageError();
      }
    })();

    return () => {
      unmounted = true;
    };
  }, []);

  const showMessageError = () => {
    setMessageError("An internal error has occurred. Please try again later.");
    setOpenError(true);
  };

  return (
    <Box my={3}>
      <CardTitle
        title="Account Settings"
        description="View your account details in this session."
      >
        <Box my={2}>
          {openError ? (
            <React.Fragment>
              <Box mb={4}>
                <Alert message={messageError} open={openError} />
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}

          <Box pb={5}>
            <Box sx={{ textAlign: "center" }}>
              <Avatar
                alt={user.name}
                {...stringAvatar(user.photo, user.name)}
                sx={{ margin: "auto", height: "80px", width: "80px" }}
              />
              <Typography variant="h6" fontWeight={400} my={2}>
                My Account
              </Typography>

              <Box my={4} />
            </Box>

            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography
                  variant="div"
                  fontSize="8pt"
                  fontWeight={400}
                  color="secondary"
                  mb={`0.25rem`}
                  sx={{
                    display: "block",
                  }}
                >
                  NAME
                </Typography>

                <Box
                  mb={3}
                  sx={{
                    p: 1.5,
                    borderRadius: ".25rem",
                    border: (theme) => `1px solid #CCCCCC`,
                  }}
                >
                  <Typography
                    variant="div"
                    fontSize="12pt"
                    fontWeight={400}
                    sx={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: "240px",
                      display: "block",
                      overflow: "hidden",
                    }}
                  >
                    {user.name || ""}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="div"
                  fontSize="8pt"
                  fontWeight={400}
                  color="secondary"
                  mb={`0.25rem`}
                  sx={{
                    display: "block",
                  }}
                >
                  EMAIL
                </Typography>
                <Box
                  mb={3}
                  sx={{
                    p: 1.5,
                    borderRadius: ".25rem",
                    border: (theme) => `1px solid #CCCCCC`,
                  }}
                >
                  <Typography
                    variant="div"
                    fontSize="12pt"
                    fontWeight={400}
                    sx={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: "240px",
                      display: "block",
                      overflow: "hidden",
                    }}
                  >
                    {user.email || ""}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CardTitle>
    </Box>
  );
};
