/* React */
import { useEffect, useState } from "react";

/* Router */
import { Navigate, Outlet, useNavigate } from "react-router-dom";

/* OAuth functions */
import { getToken } from "../../../oauth/fx/token";

/* Functions */
import { VerifyToken } from "../util";

const useAuth = () => {
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(null);

  useEffect(() => {
    let token = getToken();

    if (token) {
      const load = async () => {
        await VerifyToken(token).then((response) => {
          let data = response.user;

          if (data) {
            setIsAuth(true);
          } else {
            navigate("/logout");
          }
        });
      };

      load();
    } else {
      setIsAuth(false);
    }
  }, [navigate]);

  return isAuth;
};

export const PrivateRoutes = () => {
  const isAuth = useAuth();

  if (isAuth === null) {
    return null;
  }

  return isAuth ? <Outlet /> : <Navigate to="/login" />;
};
