/* Components */
import { Input } from "./Input";
import { Autocomplete } from "./Autocomplete";
// import InputPrice from "./InputPrice";
// import InputCPF from "./InputCPF";
// import InputCNPJ from "./InputCNPJ";
import { TextArea } from "./TextArea";
// import TextEditor from "./TextEditor";
import { RadioGroup } from "./RadioGroup";
import { Select } from "./Select";
import { Checkbox } from "./Checkbox";
import {
  DatePicker,
  DateTimePicker,
  TimePicker,
  MobileDatePicker,
} from "./DatePicker";
import { Button } from "./Button";

export const Controls = {
  Input,
  Autocomplete,
  // InputPrice,
  // InputCPF,
  // InputCNPJ,
  TextArea,
  // TextEditor,
  RadioGroup,
  Select,
  Checkbox,
  DatePicker,
  DateTimePicker,
  TimePicker,
  MobileDatePicker,
  Button,
};
