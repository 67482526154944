// react
import React, { useEffect, useState } from "react";

// material
import { Typography, LinearProgress, Box } from "@mui/material";

// components page
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" color="secondary" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          fontSize="10pt"
          sx={{
            color: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.text.primary
                : "#f9f9f9",
          }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export const Loading = () => {
  const [progress, setProgress] = useState(10);
  const [message, setMessage] = useState("Connecting...");

  useEffect(() => {
    if (progress < 100) {
      setTimeout(() => {
        const time = progress + 10;

        if (time < 40) {
          setMessage("Connecting...");
        } else if (time >= 40 && time <= 70) {
          setMessage("Initializing...");
        } else {
          setMessage("Welcome!");
        }

        setProgress(time);
      }, 200);
    }
  }, [progress]);

  return (
    <Box width="100%" textAlign="center">
      <Typography
        component="div"
        variant="body2"
        sx={{
          color: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.text.primary
              : "#CCCCCC",
        }}
      >
        {message}
      </Typography>

      <Box maxWidth="280px" width="100%" mx="auto" mt={1.5}>
        <LinearProgressWithLabel value={progress} />
      </Box>
    </Box>
  );
};
